import React, { useMemo } from 'react'
import { MessagesPage } from '../../Pages'
import { Box, Stack, Toolbar, IconButton, Typography, AppBar } from '@mui/material'
import { Close } from '@mui/icons-material'

const ChatsWrapper = ({ title, repairRequestId, wrapperStyle, isExpandedView, handleCloseExpandedView, handleInitiateChat, updateParentTotalUnreadCount, resolverId, hideAddChatButton, canInitiateChat, startChatId }) => {
  const expandedStyles = useMemo(() => (isExpandedView ? { height: "100%", width: "100%", position: "fixed", top: 0, left: 0, zIndex: 1200 } : {}), [isExpandedView])

  return (
    <Stack sx={{ ...wrapperStyle, ...expandedStyles }}>
      {isExpandedView && <AppBar position='relative'>
        <Toolbar variant='dense'>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleCloseExpandedView}
            aria-label="close"
          >
            <Close />
          </IconButton>
          {title && <Typography sx={{ ml: 2 }} variant="h6" component="div">
            {title}
          </Typography>}
        </Toolbar>
      </AppBar>}
      <Box flexGrow={1} sx={{ height: "calc(100% - 48px)" }}>
        <MessagesPage isFullWidth applyMinStyles={!isExpandedView} repairRequestId={repairRequestId} handleInitiateChat={handleInitiateChat} isAbleToCreateChat={!hideAddChatButton && !isExpandedView && canInitiateChat} updateParentTotalUnreadCount={updateParentTotalUnreadCount} resolverId={resolverId} startChatId={startChatId} />
      </Box>
    </Stack>
  )
}

export default ChatsWrapper