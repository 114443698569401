import React, { useEffect, useState } from "react";
import {
    useMediaQuery,
    Badge,
    Container,
    Typography,
    Checkbox,
    IconButton,
    Tooltip,
    FormControlLabel,
    useTheme,
    InputLabel,
    Select,
    MenuItem,
    Input,
    ListItemText,
    Menu,
    MenuList,
    ListItemIcon,
    Stack,
    Box,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import { Chat, Schedule, DashboardOutlined, MapOutlined, Image, SwapVert, ArrowUpward } from "@mui/icons-material"
import { formatDate, nl2br, history, setLocalStorageItem, getLocalStorageItem, generateLocalStorageKey, groupBy, debounce, resolverStatus } from "../../_helpers"
import DataGrid from "../../components/DataGrid";
import { ImageColumn, LabelColumn, LinkColumn } from "../../components/DataColumns";
import DataGridSetting from "../../components/DataGridSetting";
import AfterCareWorkOrderRow from "./AfterCareWorkOrderRow";
import { getOrganizations, getRepairRequestCarryOutAsTypeList, getRepairRequestLocations } from "../../apis/surveyingApis";
import { getWorkOrders } from "../../apis/aftercareApis";
import clsx from "clsx";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { URLS } from "../../apis/urls";
import { apps, userAccountRoleConstants } from "../../_constants";
import SelectCustom from "../../components/Select";
import FilterButton from "./FilterButton";
import { grey } from "@mui/material/colors";

const StyledMenuItem = withStyles((theme) => ({
    root: {
        whiteSpace: 'pre-wrap',
        padding: theme.spacing(1),
        '& div': {
            minWidth: 40,
            color: theme.palette.grey[700]
        },
        '& span': {
            color: theme.palette.grey[700]
        }
    },
}))(MenuItem);

const FILTER_OPTIONS_KEYS = {
    3: "overdue",
    4: "projectIds",
    9: "buildingIds",
    15: "statuses",
    16: "resolverOrgIds",
    17: "resolverRelIds",
    18: "locationIds",
};

const SORTING_KEYS = {
    projectName: "projectname",
    projectNo: "projectno",
    workOrderNumber: "number",
    targetCompletionDate: "targetcompletiondate",
    buildingNoIntern: "buildingnointern",
    repairRequestLocation: "location",
    description: "desc",
    address_postcode: "addresspostcode",
    address_place: "addressplace",
    relationName: "relationname",
    statusText: "status",
    name: "name",
    unreadMessagesCount: "unreadMessagesCount"
};

const STATUSES = [...Array(5)].map((a, i) => ({ status: i, label: "resolver.status." + i }));

const getAppliedFilters = (data) => {
    return data.reduce((p, c, i) => {
        const isExist = FILTER_OPTIONS_KEYS.hasOwnProperty(i);
        if (!c || (isExist && !c?.filter(a => a !== undefined || a !== null).length) || !isExist) return p;
        let value = null;
        if (i === 3) value = true;
        else if (i === 4) value = Array.isArray(c) ? c.map(a => a.projectId) : c;
        else if (i === 9 && data[4] && data[4].length) value = Array.isArray(c) ? c.map(a => a.buildingId) : c;
        else if (i === 10) value = Array.isArray(c) ? c.map(a => a.buildingId) : c;
        else if (i === 15) value = Array.isArray(c) ? c.map(a => a && a.status) : [c.status];
        else if (i === 16 || i === 17 || i === 18) value = Array.isArray(c) ? c.map(a => a.id) : typeof (c) === "object" ? [c.id] : [c];
        return {
            ...p,
            [FILTER_OPTIONS_KEYS[i]]: value
        }
    }, {});
}


const useStyles = makeStyles(theme => ({
    mainContainer: {
        height: '100%',
        width: '100%',
        overflow: 'auto',
        padding: 0
    },
    warning: {
        color: theme.palette.warning.main
    },


    iconHeader: {
        // marginRight: theme.spacing(-3)
    },
    paper: {
        boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px',
        borderRadius: 7,
    },
    CustomCheckbox: {
        marginTop: 18,
        boxShadow: 'rgba(0, 0, 0, 0.16) 0px 2px 2px',
        borderRadius: '6px',
        padding: theme.spacing(0.36, 1.2),
    },
    checkbox: {
        padding: theme.spacing(.25, 1.125)
    },
    noWrapText: {
        whiteSpace: "nowrap"
    },
    menuContent: {
        '& .MuiPopover-paper': {
            borderRadius: 10,
            boxShadow: `${theme.shadows[24]} !important`,
        },
        '& ul': {
            padding: theme.spacing(0),
        },
        '& ul > ul': {
            padding: theme.spacing(2),
        },
    },
    menuListStyle: {
        borderRadius: 10,
        maxWidth: 325,
        padding: theme.spacing(2)
    },
    expandCollapseIcon: {
        fill: theme.palette.grey[700],
        transition: "0.6s all",
        transform: "rotate(0deg)",
        [theme.breakpoints.down('md')]: {
            fontSize: 16
        },
    },
    expandLess: {
        transform: "rotate(-180deg)",
    },
    sortListIconStyle: {
        width: 40,
        minWidth: 40,
        justifyContent: 'center'
    },
    tabButton: {
        padding: `${theme.spacing(0.25, 1)} !important`,
        borderRadius: 50
    },
    tabsIcon: {
        fontSize: 18,
        [theme.breakpoints.down('sm')]: {
            fontSize: 15,
        },
    },
    mapTabsText: {
        fontSize: 12,
        textTransform: 'capitalize',
        [theme.breakpoints.down('sm')]: {
            fontSize: 10,
        },
    },
    selectedAppliedFilter: {
        backgroundColor: theme.palette.primary.main,
        '& span': {
            color: `${theme.palette.common.white} !important`
        },
        '&:hover': {
            backgroundColor: theme.palette.primary.main,
            '& span': {
                color: `${theme.palette.common.white} !important`
            },
        },
    },
    lineThrough : {
        textDecoration : "line-through"
    }
}));

export const AfterCareResolversGrid = ({ ...props }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const [defaultFilter, setDefaultFilter] = useState([...Array(19)].map(() => []));
    const [canListType, setCanListType] = useState(false);
    const [buildingFiltersData, setBuildingFiltersData] = useState([]);
    const [organizations, setOrganizations] = useState([]);
    const [relations, setRelations] = useState([]);
    const [workOrders, setWorkOrders] = useState([]);
    const [imageSize, setImageSize] = useState(20);
    const [fontSize, setFontSize] = useState(12);
    const [isLoading, setIsLoading] = useState(false);
    const [filterType, setFilterType] = useState(false);
    const [rowsPerPage, setRowsPerPage] = useState(20);
    const [currentPageInfo, setCurrentPageInfo] = useState({ index: 0 });
    const [searchText, setSearchText] = useState(null);
    const [sortData, setSortData] = useState({ name: "workOrderNumber", direction: "desc" });
    const [projectFiltersData, setProjectFiltersData] = useState([]);
    const [carryOutAsTypeList, setCarryOutAsTypeList] = useState([]);
    const [locations, setLocations] = useState([]);
    const [sortAnchorEl, setSortAnchorEl] = useState(null);
    const [columnsVisibility, setColumnsVisibility] = useState([
        { name: "unreadMessagesCount", visible: true },
        { name: "isRequiredHandling", visible: true },
        { name: "projectName", visible: true },
        { name: "repairRequestImages", visible: true },
        { name: "workOrderNumber", visible: true },
        { name: "description", visible: true },
        { name: "buildingNoIntern", visible: true },
        { name: "addressText", visible: true },
        { name: "address_postcode", visible: true },
        { name: "address_place", visible: true },
        { name: "targetCompletionDate", visible: true },
        { name: "statusText", visible: true },
        { name: "name", visible: true },
        { name: "repairRequestLocation", visible: true },
        { name: "appointmentDateTime", visible: true },
    ]);
    const [rowsSelected, setRowsSelected] = useState({ data: [], list: [] });
    const app = useSelector(state => state.app);
    const { all, selected } = useSelector(state => state.buildings);
    const [buildings, setBuildings] = useState([]);
    const theme = useTheme();
    const isMobileOrTab = useMediaQuery(theme.breakpoints.down('md'));
    const [optionsDataLoading, setOptionsDataLoading] = useState({ location: false, carryOutAsType: false });

    useEffect(() => {
        setBuildings(all.filter(a => (!a.roles || a.roles.includes(userAccountRoleConstants.aftercareEmployee) || a.roles.includes(userAccountRoleConstants.siteManager))));
    }, [all]);

    useEffect(() => {
        const { location } = props;
        if (location.state && location.state.filter) {
            let filter = [...defaultFilter];
            switch (location.state.filter) {
                case "newWorkOrder": {
                    filter = Object.assign(filter, { [15]: [{ status: resolverStatus.New, label: "resolver.status." + resolverStatus.New }] });
                    break;
                }
                case "openWorkOrders": {
                    filter = Object.assign(filter, { [15]: [{ status: resolverStatus.New, label: "resolver.status." + resolverStatus.New }, { status: resolverStatus.Informed, label: "resolver.status." + resolverStatus.Informed }] });
                    break;
                }
                case "overdueWorkOrders": {
                    filter = Object.assign(filter, { [3]: [t("general.overdue")] });
                    break;
                }
                case "subContractorName": {
                    filter = Object.assign(filter, { [16]: [location.state.contractor] });
                    break;
                }
                case "subContractorRelation": {
                    filter = Object.assign(filter, { [17]: [location.state.relation] });
                    break;
                }
                case "building": {
                    filter = Object.assign(filter, { [9]: [location.state.selectedProject] });
                    break;
                }

                default: { break }
            }
            if (location.state.selectedProject) {
                filter = Object.assign(filter, { [4]: [location.state.selectedProject] });
            }
            if (location.state.subContractorName)
                filter = Object.assign(filter, { [16]: [location.state.contractor] });
            if (location.state.subContractorRelation)
                filter = Object.assign(filter, { [17]: [location.state.relation] });

            setFilterType(location.state);
            const state = { ...props.history.location.state };
            delete state.filter;
            delete state.selectedProject;
            history.replace({ ...props.history.location, state });
            setDefaultFilter(filter);
            setLocalStorageItem(`AfterCare_ResolverWorkOrders_V2_Columns_Filters`, filter);

        } else {
            const filter = getLocalStorageItem(`AfterCare_ResolverWorkOrders_V2_Columns_Filters`);
            filter && setDefaultFilter(filter);
        }

        const localColumnsVisibility = getLocalStorageItem("AfterCare_ResolverWorkOrders_V2_ColumnsVisibility");
        if (localColumnsVisibility) setColumnsVisibility(localColumnsVisibility);
        else setLocalStorageItem("AfterCare_ResolverWorkOrders_V2_ColumnsVisibility", columnsVisibility);

        let sortDataLocal = getLocalStorageItem("AfterCare_ResolverWorkOrders_V2_SortOrder");
        if (sortDataLocal) {
            setSortData(sortDataLocal);
        } else sortDataLocal = sortData;

        if (buildings && buildings.length) {
            const projectIds = groupBy(buildings, "projectId") || {};
            let data = {}
            setProjectFiltersData(Object.keys(projectIds).map(a => projectIds[a][0]));
            const filter = getLocalStorageItem(`AfterCare_ResolverWorkOrders_V2_Columns_Filters`);
            if (filter) {
                data = getAppliedFilters(filter);
            }
            let rowsPerPageLocal = getLocalStorageItem("AfterCare_ResolverWorkOrders_RowsPerPageLocal");
            if (!rowsPerPageLocal) {
                setLocalStorageItem("AfterCare_ResolverWorkOrders_RowsPerPageLocal", rowsPerPage);
                rowsPerPageLocal = rowsPerPage;
            } else {
                setRowsPerPage(rowsPerPageLocal)
            };
            UpdateWorkOrders(isLoading, rowsPerPageLocal, undefined, undefined, sortDataLocal ? { ...data, orderBy: sortDataLocal.name, orderByAsc: sortDataLocal.direction === "asc" } : data)
        }

        const size = getLocalStorageItem("AfterCare_ResolverWorkOrders_List_Image_Size");
        const fontSizeLocal = getLocalStorageItem("AfterCare_ResolverWorkOrders_Grid_Font_Size");
        let canListTypeLocal = getLocalStorageItem("AfterCare_ResolverWorkOrders_Grid_Style");
        canListTypeLocal = canListTypeLocal === null ? isMobileOrTab : canListTypeLocal;

        setImageSize(+size || imageSize);
        setFontSize(+fontSizeLocal || fontSize);
        setCanListType(canListTypeLocal);
        getAdditionalFiltersData();
    }, [buildings]);

    const getAdditionalFiltersData = () => {
        setOptionsDataLoading(a => ({ location: true, carryOutAsType: true }));
        getRepairRequestLocations().then(res => {
            setLocations(res.data);
            setOptionsDataLoading(a => ({ ...a, location: false }));
        }).catch(() => {
            setOptionsDataLoading(a => ({ ...a, location: false }));
        });
        getRepairRequestCarryOutAsTypeList().then(res => {
            setCarryOutAsTypeList(res.data);
            setOptionsDataLoading(a => ({ ...a, carryOutAsType: false }));
        }).catch(() => {
            setOptionsDataLoading(a => ({ ...a, carryOutAsType: false }));
        });
    };

    const isColumnVisible = (columnName) => {
        const isSmallScreen = true; //isWidthDown('sm', width);
        var column = columnsVisibility.find((x) => x.name === columnName);
        if (column) {
            return column.visible;
        } else if (isSmallScreen) {
            return false;
        } else {
            return true;
        }
    };

    const getModifiedResponse = (res) => {
        const data = Object.assign([], res);
        data.forEach((workOrder, index) => {
            var building = all.find((x) => x.buildingId === workOrder.buildingId);
            const projectBuilding = all.find((x) => (x.projectId === workOrder.projectId || x.buildingId === workOrder.buildingId));
            workOrder.buildingNoIntern = building && building.buildingNoIntern;
            workOrder.buildingNoExtern = building && building.buildingNoExtern;

            workOrder.projectName = projectBuilding && projectBuilding.projectName.replace(`${projectBuilding.projectNo} - `, "");
            workOrder.projectNo = projectBuilding && projectBuilding.projectNo;

            workOrder.statusText = t("resolver.status." + workOrder.status);
            if (workOrder.address) {
                workOrder.addressText =
                    (!!workOrder.address.street ? workOrder.address.street : "") +
                    " " +
                    (!!workOrder.address.houseNo ? workOrder.address.houseNo : "") +
                    (!!workOrder.address.houseNoAddition
                        ? " " + workOrder.address.houseNoAddition
                        : "");

                workOrder.address_postcode = workOrder.address.postcode;
                workOrder.address_place = workOrder.address.place;
            }
        });
        return data
    }

    const UpdateWorkOrders = (loading = false, rowsPerPage = 10, page = 0, search = "", data = {}) => {
        if (!search) search = "";
        if (all && all.length) {
            const projectIds = data.projectIds || [];
            setIsLoading(true);
            setWorkOrders([]);
            const orderByObject = data.orderBy ? { orderBy: SORTING_KEYS[data.orderBy] } : {};
            getWorkOrders({
                app,
                search,
                index: page,
                rowsPerPage,
                ...data,
                ...orderByObject
            }).then(({ data: { results, ...res } }) => {
                results = getModifiedResponse(results);
                setWorkOrders(results);
                setIsLoading(false);
                setCurrentPageInfo(res);
                setBuildingFiltersData(buildings.filter(a => !projectIds?.length || !!projectIds.find(p => p === a.projectId)).sort((a, b) => a.buildingNoExtern - b.buildingNoExtern));
               app !== apps.resolverModule && getOrganizations(projectIds).then((res) => {
                    const orgs = res.data;
                    setOrganizations(orgs);
                    var rels = []
                    orgs.filter(o => data && data.resolverOrgIds && data.resolverOrgIds.length ? !!data.resolverOrgIds.find(a => a === o.id) : true)
                        .map(item => {
                            item.relations.map(rel => {
                                rels.push(rel);
                            });
                        });
                    setRelations(rels);
                }).catch(er => {
                    console.log(er, "er");
                });
            }).catch((error) => {
                setCurrentPageInfo(a => ({ ...a, index: page, totalResults: 0 }));
                setIsLoading(false);
            });
        }
    }

    const overViewHandler = () => {
        history.push(app === apps.aftercare ? "/nazorg/werkbonnen/onderaannemer" : `/werk/${selected?.projectNo}/werkbonnen/onderaannemer`)
    }

    const listToMapRedirectHandler = (event, selectedRequest) => {
        history.push({
            pathname: "/meldingen/map",
            state: {
                selectedRequest: {
                    ...selectedRequest,
                    desc: selectedRequest.description,
                    textResolvers: [selectedRequest.name],
                    attachments: selectedRequest.repairRequestImages || [],
                    number: selectedRequest.workOrderNumber,
                    status: t("resolver.status." + selectedRequest.status),
                },
                requests: workOrders.map(p => ({
                    ...p,
                    desc: selectedRequest.description,
                    textResolvers: [p.name],
                    attachments: p.repairRequestImages || [],
                    number: p.workOrderNumber,
                    status: t("resolver.status." + p.status),
                })),
                buildingId: selectedRequest.buildingId
            }
        })
    }

    const handleResizeImage = (value) => {
        setImageSize(+value);
        setLocalStorageItem("AfterCare_ResolverWorkOrders_List_Image_Size", +value);
    }
    const handleResizeFont = (value) => {
        setFontSize(+value);
        setLocalStorageItem("AfterCare_ResolverWorkOrders_Grid_Font_Size", +value);
    }

    const handleChangeGridStyle = (val) => {
        setCanListType(val)
        setLocalStorageItem("AfterCare_ResolverWorkOrders_Grid_Style", val);
    }

    const search = debounce((newRowsPerPage, page, searchQuery, data) => {
        UpdateWorkOrders(isLoading, newRowsPerPage, page, searchQuery, data);
        setSearchText(searchQuery);
    }, 500);

    const companyFilterHandler = (user) => {
        if (!defaultFilter[16].find(a => a.id === user.organisatonId)) {
            const sortedData = sortData ? {
                orderBy: sortData.name,
                orderByAsc: sortData.direction === "asc",
            } : {};
            const filter = defaultFilter[16].concat({ ...user, id: user.organisatonId });
            const filters = Object.assign(defaultFilter || [], { 16: defaultFilter[16] ? filter : [{ ...user, id: user.organisatonId }] });
            const data = getAppliedFilters(filters);
            setLocalStorageItem(`AfterCare_ResolverWorkOrders_V2_Columns_Filters`, filters);
            setDefaultFilter(filters)
            UpdateWorkOrders(true, rowsPerPage, currentPageInfo.index, searchText, { ...data, ...sortedData });
        }
    };

    const updateSort = (sort) => {
        if (sort) {
            const sortedData = sortData ? {
                orderBy: sort.name,
                orderByAsc: sortData.name === sort.name ? sortData.direction === "asc" ? false : true : true,
            } : { orderBy: sort.name, orderByAsc: true };
            const data = getAppliedFilters(defaultFilter);
            const sortBy = { name: sortedData.orderBy, direction: sortedData.orderByAsc ? "asc" : "desc" };
            setLocalStorageItem('AfterCare_ResolverWorkOrders_V2_SortOrder', sortBy);
            setSortData(sortBy);
            UpdateWorkOrders(true, rowsPerPage, currentPageInfo.index, searchText, { ...data, ...sortedData });
        }
    };

    const handleApplyQuickFilter = (status) => {
        let appliedStatus = [];
        const sortedData = sortData ? {
            orderBy: sortData.name,
            orderByAsc: sortData.direction === "asc",
        } : {};

        let filter;

        switch (status) {
            case "new":
                if (defaultFilter[15]?.find(item => item.status === resolverStatus.New)) {
                    appliedStatus = defaultFilter[15]?.filter(item => item.status !== resolverStatus.New)
                } else {
                    filter = [{ status: resolverStatus.New, label: "resolver.status." + resolverStatus.New }]
                    appliedStatus = [...defaultFilter[15], ...filter]
                }

                break;
            case "inProgress":
                if (defaultFilter[15]?.find(item => item.status === resolverStatus.Informed)) {
                    appliedStatus = defaultFilter[15]?.filter(item => item.status !== resolverStatus.Informed)
                } else {
                    filter = [{ status: resolverStatus.Informed, label: "resolver.status." + resolverStatus.Informed }]
                    appliedStatus = [...defaultFilter[15], ...filter]
                }

                break;
            case "dueDate":
                if (!defaultFilter[3].length) {
                    filter = [t("general.overdue")]
                    appliedStatus.push(...filter);
                }
                break;
            case "completedOrRejected":
                if (defaultFilter[15]?.find(item => item?.status === resolverStatus.Completed) && defaultFilter[15]?.find(item => item?.status === resolverStatus.TurnedDown)) {
                    appliedStatus = defaultFilter[15]?.filter(item => ![resolverStatus.Completed, resolverStatus.TurnedDown].includes(item.status))
                } else {
                    filter = [{ status: resolverStatus.Completed, label: "resolver.status." + resolverStatus.Completed }, { status: resolverStatus.TurnedDown, label: "resolver.status." + resolverStatus.TurnedDown }]
                    appliedStatus = [...defaultFilter[15], ...filter]
                }

                break;
            default:
                break;
        }



        const filters = Object.assign(defaultFilter || [], status === "dueDate" ? { [3]: [...appliedStatus] } : {
            [15]: [...new Map([...appliedStatus].map(item =>
                [item["status"], item])).values()]
        });
        const data = getAppliedFilters(filters);
        setLocalStorageItem(`AfterCare_ResolverWorkOrders_V2_Columns_Filters`, filters);
        setDefaultFilter(filters);
        UpdateWorkOrders(true, rowsPerPage, currentPageInfo.index, searchText, { ...data, ...sortedData });
    }

    const canAppliedQuickFilter = () => {

        let appliedFilter = {
            new: false,
            inprogress: false,
            dueDate: false,
            completedOrRejected: false
        }

        if (defaultFilter && defaultFilter[15]) {

            let isCompleted = !!defaultFilter[15].find(x => x.status == resolverStatus.Completed)
            let isRejected = !!defaultFilter[15].find(x => x.status == resolverStatus.TurnedDown)
            let isNew = !!defaultFilter[15].find(x => x.status == resolverStatus.New)
            let isInProgress = !!defaultFilter[15].find(x => x.status == resolverStatus.Informed)

            appliedFilter.completedOrRejected = isCompleted && isRejected;
            appliedFilter.new = isNew;
            appliedFilter.inprogress = isInProgress
        }

        if (defaultFilter && defaultFilter[3] && defaultFilter[3]?.length) {
            appliedFilter.dueDate = true
        }


        return appliedFilter
    };


    const getWorkOrdersForPrintAndDownload = async () => {
        const sortDataLocal = sortData ? {
            orderBy: sortData.name,
            orderByAsc: sortData.direction === "asc",
        } : {};
        const columnNames = columns.filter(c => c && c.options && c.options.display !== "excluded").reduce((p, c) => ({ ...p, [c.name]: c.name }), {});
        const data = { ...getAppliedFilters(defaultFilter), ...sortDataLocal };
        const { data: { results } } = await getWorkOrders({
            app,
            rowsPerPage: 0,
            search: searchText,
            ...data
        });
        const modifiedData = getModifiedResponse(results);
        return modifiedData.map(p => {
            return Object.keys(columnNames).reduce((prev, key) => {
                const obj = { ...prev };
                if (key === "isRequiredHandling") {
                    const array = [];
                    (p.overdue || p.is48HoursReminder) && array.push({ icon: "Schedule", color: p.overdue ? "error" : "warning" })
                    obj[key] = array;
                }
                else if (key.toLowerCase().includes("date")) obj[key] =p[key] ? formatDate(new Date(p[key])) :"";
                else obj[key] = typeof p[key] === "string" ? t(p[key]) : p[key];
                return obj
            }, {});
        });
    }


    const appliedQuickFilter = canAppliedQuickFilter();
    const columns = [
        {
            name: "resolverId",
            options: {
                display: "excluded",
                filter: false,
                print: false,
                download: false,
            },
        },
        {
            name: "unreadMessagesCount",
            label: t("chat.messages"),
            options: {
                display: isColumnVisible("unreadMessagesCount"),
                filter: false,
                download: false,
                maxWidth: 60,
                customHeadLabelRender: (columnMeta) => {
                    return <div className={classes.iconHeader}>
                        <Chat fontSize="small" />
                    </div>
                },
                customBodyRenderLite: (rowIndex) => {
                    const workOrder = workOrders[rowIndex];
                    if (!workOrder?.unreadMessagesCount) return ""
                    return (
                        <Badge badgeContent={workOrder.unreadMessagesCount} color="secondary">
                            <Chat fontSize="small" />
                        </Badge>
                    )
                },
            },
        },
        {
            name: "isRequiredHandling",
            label: " ",
            options: {
                display: isColumnVisible("isRequiredHandling"),
                filterList: defaultFilter ? defaultFilter[2] : [],
                filter: false,
                sort: false,
                print: true,
                maxWidth: 70,
                download: false,
                customHeadLabelRender: (columnMeta) => {
                    return <div className={classes.iconHeader}>
                        <Schedule fontSize="small" />
                    </div>
                },
                customBodyRenderLite: (rowIndex) => {
                    const workOrder = workOrders[rowIndex];
                    if (!workOrder) return ""
                    return (
                        <div className={classes.noWrapText}>
                            {workOrder.overdue ? (
                                <Tooltip title={<Typography>{t("general.overdue")}</Typography>}>
                                    <Schedule fontSize="small" color="error" />
                                </Tooltip>
                            ) : workOrder.is48HoursReminder ? (
                                <Tooltip title={<Typography>{t("general.48hours")}</Typography>}>
                                    <Schedule fontSize="small" className={classes.warning} />
                                </Tooltip>
                            ) : ("")}
                        </div>
                    );
                },
            },
        },
        {
            name: "overdue",
            label: " ",
            options: {
                display: "excluded",
                filter: true,
                filterList: defaultFilter ? defaultFilter[3] : [],
                print: false,
                sort: false,
                download: false,
                filterType: 'custom',
                filterOptions: {
                    fullWidth: true,
                    names: [t('general.overdue')],
                    label: t('general.overdue'),
                    logic: (value, filters, row) => {
                        return false;
                    },
                    display: (filterList, onChange, index, column) => {
                        return (
                            <div className={classes.CustomCheckbox}>
                                <FormControlLabel control={<Checkbox className={classes.checkbox} checked={!!filterList.find(p => p[0] === column.filterOptions.label)} color="primary"
                                    onChange={event => {
                                        if (event.nativeEvent.target.checked)
                                            filterList[index][0] = column.filterOptions.label;
                                        else
                                            filterList[index] = [];
                                        onChange(filterList[index], index, column);
                                    }}
                                />} label={column.filterOptions.label} />
                            </div>
                        )
                    }
                },
            },
        },
        {
            name: "projectName",
            label: t("general.projectName"),
            options: {
                display: isColumnVisible("projectName"),
                filterList: defaultFilter ? defaultFilter[4] : [],
                filter: true,
                sort: true,
                maxWidth: 120,
                customBodyRenderLite: (rowIndex) => {
                    const workOrder = workOrders[rowIndex];
                    if (!workOrder) return ""
                    return <LabelColumn tooltipLabel={workOrder.projectName} label={workOrder.projectName} />
                },
                filterType: "custom",
                filterOptions: {
                    fullWidth: true,
                    names: projectFiltersData,
                    logic: (value, filters, row) => {
                        return false;
                    },
                    display: (filterList, onChange, index, column) => {
                        const value = filterList[index].length ? filterList[index][0] : null;
                        return (

                            <SelectCustom
                                label={t("general.projectName")}
                                searchKey="projectName"
                                sortDirection="desc"
                                selectProps={{
                                    value,
                                    onChange: (e, newValue) => {
                                        onChange(newValue ? [newValue] : [], index, column);
                                    },
                                }}
                                idKey={"projectId"}
                                labelKey={"projectName"}
                                options={projectFiltersData}
                            />
                        );
                    },
                },
                customFilterListOptions: {
                    render: function (record) {
                        return record.map(({ projectName }) => <span key={projectName}>{projectName}</span>)
                    }
                }
            },
        },
        {
            name: "projectNo",
            label: t("general.projectNumber"),
            options: {
                display: isColumnVisible("projectName"),
                filter: false,
                maxWidth: 120,
                sort: true,
                customBodyRenderLite: (rowIndex) => {
                    const workOrder = workOrders[rowIndex];
                    if (!workOrder) return ""
                    return <LabelColumn tooltipLabel={workOrder.projectNo} label={workOrder.projectNo} />
                },
            },
        },
        {
            name: "repairRequestImages",
            label: t("general.image"),
            options: {
                display: isColumnVisible("repairRequestImages"),
                filter: false,
                sort: false,
                maxWidth: 60,
                print: true,
                download: false,
                customBodyRenderLite: (rowIndex) => {
                    const workOrder = workOrders[rowIndex];
                    if (!workOrder) return ""
                    var imageId = workOrder.repairRequestImages && workOrder.repairRequestImages.length > 0 && workOrder.repairRequestImages[0].attachmentId;
                    return <ImageColumn size={imageSize} src={imageId ? URLS.GET_ATTACHMENT_THUMBNAIL + imageId : null} />
                },
                customHeadLabelRender: (columnMeta) => {
                    return <div style={{ textAlign: "center" }}><Image /></div>
                }
            },
        },
        {
            name: "workOrderNumber",
            label: t("general.number"),
            options: {
                display: isColumnVisible("workOrderNumber"),
                filter: false,
                maxWidth: 90,
                sort: true,
                customBodyRenderLite: (rowIndex) => {
                    const workOrder = workOrders[rowIndex];
                    if (!workOrder) return ""
                    return <LinkColumn className={classes.noWrapText} label={workOrder.workOrderNumber} to={{ pathname: (app === apps.aftercare ? "/nazorg/werkbon/" : `/werk/${selected?.projectNo}/werkbon/`) + workOrder.resolverId, state: { fromWorkOrderGrids: true } }} />
                },
            },
        },
        {
            name: "description",
            label: t("general.description"),
            options: {
                filter: false,
                maxWidth: 110,
                display: isColumnVisible("description"),
                sort: true,
                customBodyRenderLite: (rowIndex) => {
                    const workOrder = workOrders[rowIndex];
                    if (!workOrder) return ""
                    return (
                        <LabelColumn
                            label={workOrder.description}
                            tooltipLabel={<>
                                {nl2br(workOrder.workOrderText)}
                                {workOrder.explanation && (
                                    <>
                                        <br />
                                        {nl2br(workOrder.explanation)}
                                    </>
                                )}
                            </>}
                        />
                    );
                },
            },
        },
        {
            name: "buildingNoIntern",
            label: t("general.buildingNumber"),
            options: {
                display: isColumnVisible("buildingNoIntern"),
                maxWidth: 120,
                filter: true,
                filterList: defaultFilter ? defaultFilter[9] : [],
                sort: true,
                customBodyRenderLite: (rowIndex) => {
                    const workOrder = workOrders[rowIndex];
                    if (!workOrder) return ""
                    return <LabelColumn tooltipLabel={workOrder.buildingNoIntern} label={workOrder.buildingNoIntern} />
                },
                filterType: "custom",
                filterOptions: {
                    fullWidth: true,
                    names: [...new Map(buildingFiltersData.map(item =>
                        [item["buildingId"], item])).values()],
                    logic: (value, filters, row) => {
                        return false;
                    },
                    display: (filterList, onChange, index, column) => {
                        const list = defaultFilter ? defaultFilter[9] : [];
                        return (
                            <SelectCustom
                                label={t("general.buildingNumber")}
                                searchKey="buildingNoIntern"
                                selectProps={{
                                    multiple: true,
                                    value: list,
                                    onChange: (e, newValues) => {
                                        onChange(newValues, index, column);
                                    },
                                    disabled: !defaultFilter || !defaultFilter[4].length
                                }}
                                selectedList={list}
                                idKey={"buildingId"}
                                labelKey={"buildingNoIntern"}
                                options={[...new Map(buildingFiltersData.map(item =>
                                    [item["buildingId"], item])).values()]}
                            />
                        );
                    },
                },
                customFilterListOptions: {
                    render: function (record) {
                        return record.map(({ buildingNoIntern }) => <span key={buildingNoIntern}>{buildingNoIntern}</span>)
                    }
                }
            },
        },

        {
            name: "addressText",
            label: t("general.objectAddress"),
            options: {
                maxWidth: 130,
                display: isColumnVisible("addressText"),
                filter: true,
                filterList: defaultFilter ? defaultFilter[10] : [],
                customBodyRenderLite: (rowIndex) => {
                    const workOrder = workOrders[rowIndex];
                    if (!workOrder) return ""
                    return <LabelColumn tooltipLabel={workOrder.addressText} label={workOrder.addressText} />
                },
                sort: false,
                filterType: "custom",
                filterOptions: {
                    fullWidth: true,
                    names: [...new Map(buildingFiltersData.map(item =>
                        [item["address"], item])).values()].filter((item) => !!item.address),
                    logic: (value, filters, row) => {
                        return false;
                    },
                    display: (filterList, onChange, index, column) => {
                        const list = defaultFilter ? defaultFilter[10] : [];

                        return (
                            <SelectCustom
                                label={t("general.address")}
                                searchKey="address"
                                selectProps={{
                                    multiple: true,
                                    value: list,
                                    onChange: (e, newValues) => {
                                        onChange(newValues, index, column);
                                    },
                                }}
                                selectedList={list}
                                idKey={"buildingId"}
                                labelKey={"address"}
                                options={[...new Map(buildingFiltersData.map(item =>
                                    [item["address"], item])).values()].filter((item) => !!item.address)}
                            />
                        );
                    },
                },
                customFilterListOptions: {
                    render: function (record) {
                        return record.map(({ address }) => <span key={address}>{address}</span>)
                    }
                }
            },
        },
        {
            name: "address_postcode",
            label: t("general.address.postcode"),
            options: {
                maxWidth: 90,
                display: isColumnVisible("address_postcode"),
                filter: false,
                customBodyRenderLite: (rowIndex) => {
                    const workOrder = workOrders[rowIndex];
                    if (!workOrder) return ""
                    return <LabelColumn tooltipLabel={workOrder.address_postcode} label={workOrder.address_postcode} />
                },
                sort: true,
            },
        },
        {
            name: "address_place",
            label: t("general.address.city"),
            options: {
                display: isColumnVisible("address_place"),
                filter: false,
                customBodyRenderLite: (rowIndex) => {
                    const workOrder = workOrders[rowIndex];
                    if (!workOrder) return ""
                    return <LabelColumn tooltipLabel={workOrder.address_place} label={workOrder.address_place} />
                },
                maxWidth: 100,
                sort: true,
            },
        },
        {
            name: "targetCompletionDate",
            label: t("repairRequest.targetCompletionDate.shortLabel"),
            options: {
                display: isColumnVisible("targetCompletionDate"),
                maxWidth: 200,
                customBodyRenderLite: (rowIndex) => {
                    const workOrder = workOrders[rowIndex];
                    if (!workOrder) return ""
                    return <LabelColumn date={new Date(workOrder.targetCompletionDate)} />
                },
                filter: false,
                sort: true,
            },
        },
        {
            name: 'hasSignature',
            label: t("general.signed"),
            options: {
                display: true,
                filter: false,
                download: false,
                sort: false,
                maxWidth: 60,
                customBodyRenderLite: (rowIndex) => {
                    const workOrder = workOrders[rowIndex];
                    if (!workOrder) return ""
                    const label = workOrder?.hasSignature ? t("general.yes") : t("general.no")
                    return <LabelColumn tooltipLabel={label} label={label} />
                },
            }
        },
        {
            name: "statusText",
            label: t("general.status"),
            options: {
                display: isColumnVisible("statusText"),
                filter: true,
                maxWidth: 100,
                filterList: defaultFilter ? defaultFilter[15] : [],
                sort: true,
                customBodyRenderLite: (rowIndex) => {
                    const workOrder = workOrders[rowIndex];
                    if (!workOrder) return ""
                    return <LabelColumn label={workOrder.statusText} tooltipLabel={workOrder.statusText} color={workOrder && workOrder.overdue && theme.palette.error.dark} />
                },
                filterType: "custom",
                filterOptions: {
                    fullWidth: true,
                    names: STATUSES,
                    logic: (value, filters, row) => {
                        return false;
                    },
                    display: (filterList, onChange, index, column) => {
                        const list = defaultFilter ? defaultFilter[15] : [];
                        return (
                            <SelectCustom
                                label={t("general.status")}
                                searchKey="label"
                                selectProps={{
                                    multiple: true,
                                    value: list,
                                    onChange: (e, newValues) => {
                                        const valuesToUpdate = newValues.filter(x => newValues.filter(y => x.label === y.label).length === 1);
                                        onChange(valuesToUpdate, index, column);
                                    },
                                }}
                                selectedList={list}
                                idKey={"label"}
                                labelKey={"label"}
                                options={STATUSES}
                            />

                        );
                    },
                },
                customFilterListOptions: {
                    render: function (record) {
                        return record.map(a => a && <span key={a.label}>{t(a.label)}</span>)
                    }
                }
            },
        },
        {
            name: "name",
            label: t("general.name"),
            options: {
                display: isColumnVisible("name"),
                filter: app !== apps.resolverModule,
                maxWidth: 200,
                filterList: defaultFilter ? defaultFilter[16] : [],
                sort: true,
                customBodyRenderLite: (rowIndex) => {
                    const workOrder = workOrders[rowIndex];
                    if (!workOrder) return ""
                    return <LabelColumn label={workOrder.name} tooltipLabel={workOrder.name} onLabelClick={() => companyFilterHandler(workOrder)} />
                },
                filterType: "custom",
                filterOptions: {
                    fullWidth: true,
                    names: organizations,
                    logic: (value, filters, row) => {
                        return false;
                    },
                    display: (filterList, onChange, index, column) => {
                        const list = defaultFilter ? defaultFilter[16] : [];
                        return (
                            <SelectCustom
                                label={t("general.resolvers")}
                                searchKey="name"
                                selectProps={{
                                    multiple: true,
                                    value: list,
                                    onChange: (e, newValues) => {
                                        const valuesToUpdate = newValues.filter(x => newValues.filter(y => x.id === y.id).length === 1);
                                        onChange(valuesToUpdate, index, column);
                                    }
                                }}
                                selectedList={list}
                                idKey={"id"}
                                labelKey={"name"}
                                options={organizations}
                            />
                        );
                    },
                },
                customFilterListOptions: {
                    render: function (record) {
                        return record.map((a) => a && <span key={a.id}>{a.name}</span>)
                    }
                }
            },
        },
        {
            name: "relationName",
            label: t("workOrders.relation"),
            options: {
                display: isColumnVisible("relationName"),
                maxWidth: 150,
                filter: app !== apps.resolverModule,
                filterList: defaultFilter ? defaultFilter[17] : [],
                sort: true,
                customBodyRenderLite: (rowIndex) => {
                    const workOrder = workOrders[rowIndex];
                    if (!workOrder) return ""
                    return <LabelColumn tooltipLabel={workOrder.relationName} label={workOrder.relationName} />
                },
                filterType: "custom",
                filterOptions: {
                    fullWidth: true,
                    names: relations,
                    logic: (value, filters, row) => {
                        return false;
                    },
                    display: (filterList, onChange, index, column) => {
                        const list = defaultFilter ? defaultFilter[17] : [];
                        return (
                            <SelectCustom
                                label={t("workOrders.relation")}
                                searchKey="name"
                                selectProps={{
                                    multiple: true,
                                    value: list,
                                    onChange: (e, newValues) => {
                                        const valuesToUpdate = newValues.filter(x => newValues.filter(y => x.id === y.id).length === 1);
                                        onChange(valuesToUpdate, index, column);
                                    }
                                }}
                                selectedList={list}
                                idKey={"id"}
                                labelKey={"name"}
                                options={relations}
                            />
                        );
                    },
                },
                customFilterListOptions: {
                    render: function (record) {
                        return record.map(({ name }) => <span key={name}>{name}</span>)
                    }
                }
            },
        },
        {
            name: "repairRequestLocation",
            label: t("general.location"),
            options: {
                display: isColumnVisible("repairRequestLocation"),
                filterList: defaultFilter ? defaultFilter[18] : [],
                filter: false,
                sort: true,
                maxWidth: 120,
                customBodyRenderLite: (rowIndex) => {
                    const workOrder = workOrders[rowIndex];
                    if (!workOrder) return ""
                    return (
                        <LabelColumn
                            label={workOrder.repairRequestLocation}
                            tooltipLabel={workOrder.repairRequestLocation}
                            Icon={MapOutlined}
                            clickable
                            showIcon={workOrder.drawingPinLocation}
                            onClick={(e) => listToMapRedirectHandler(e, workOrder)} />
                    )
                },
                filterType: "custom",
                filterOptions: {
                    names: locations,
                    logic: (value, filters, row) => {
                        return false;
                    },
                    display: (filterList, onChange, index, column) => {
                        return (
                            <>
                                <InputLabel id="project-select">{t("general.location")}</InputLabel>
                                <Select
                                    labelId="location-list-label"
                                    id="location-list-checkbox"
                                    multiple
                                    value={filterList[index].map(a => a.id)}
                                    onChange={({ target: { value: ids } }) => {
                                        onChange(ids.map(id => locations.find(a => a.id === id)), index, column);
                                    }}
                                    input={<Input />}
                                    renderValue={(selected) => selected.map(id => locations.find(a => a.id === id).name).join(', ')}
                                    MenuProps={{
                                        PaperProps: {
                                            style: {
                                                maxHeight: "50%",
                                            },
                                        },
                                    }}
                                >
                                    {locations.map(({ name, id }) => (
                                        <MenuItem key={id} value={id}>
                                            <Checkbox color="primary" checked={filterList[index].some(a => a.id === id)} />
                                            <ListItemText primary={name} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </>
                        );
                    },
                },
                customFilterListOptions: {
                    render: function (record) {
                        return record.map(({ name }) => <span key={name}>{name}</span>)
                    }
                }
            },
            
        },
        {
            name: "appointmentDateTime",
            label:t("general.appointment"),
            options: {
                maxWidth: 200,
                filter: false,
                sort: false,
                display: isColumnVisible("appointmentDateTime"),
                customBodyRenderLite: (rowIndex) => {
                    const workOrder = workOrders[rowIndex];
                    if (!workOrder) return ""
                    const appointmentDateTime = formatDate(new Date(workOrder?.appointmentDateTime) , true)
                    return workOrder?.appointmentCancelled && workOrder?.appointmentDateTime ?
                        <LabelColumn tooltipLabel={appointmentDateTime} label={appointmentDateTime} color={theme.palette.error.dark} spanClass={classes.lineThrough} />
                        : workOrder?.appointmentDateTime ?
                            <LabelColumn tooltipLabel={appointmentDateTime} label={appointmentDateTime} />
                            : <Typography align="center">-</Typography>
                },
            },
        },
    ];

    const options = {
        rowsPerPage: rowsPerPage,
        pagination: true,
        rowsPerPageOptions: [5, 10, 20, 50, 75, 100],
        serverSide: true,
        download: true,
        print: true,
        count: currentPageInfo && currentPageInfo.totalResults,
        page: currentPageInfo && currentPageInfo.index,
        sortOrder: sortData,
        customToolbarSelect: (selectedRows) => (
            <></>
        ),
        onFilterChange: (identifier, list) => {
            if (identifier === "projectName") {
                list[9] = []; list[10] = []
            }
            if (identifier === "addressText" && list[9].length) {
                list[4] = [list[10][0]];
                list[9] = list[10]
            }
            if (identifier === "buildingNoIntern") {
                list[10] = []
            }
            if (!list[4].length) { list[9] = []; list[10] = [] };
            const data = getAppliedFilters(list);
            setLocalStorageItem(`AfterCare_ResolverWorkOrders_V2_Columns_Filters`, list);
            setDefaultFilter(list);
            UpdateWorkOrders(isLoading, rowsPerPage, currentPageInfo.index, searchText, data);
        },
        onViewColumnsChange: (changedColumn, action) => {
            const listToUpdate = Object.assign([], columnsVisibility);
            let column = listToUpdate.find((x) => x.name === changedColumn);
            if (action == "add") {
                if (column) column.visible = true;
                else listToUpdate.push({ name: changedColumn, visible: true });
                setColumnsVisibility(listToUpdate);
                setLocalStorageItem("AfterCare_ResolverWorkOrders_V2_ColumnsVisibility", listToUpdate);
            } else if (action == "remove") {
                if (column) column.visible = false;
                else listToUpdate.push({ name: changedColumn, visible: false });
                setColumnsVisibility(listToUpdate);
                setLocalStorageItem("AfterCare_ResolverWorkOrders_V2_ColumnsVisibility", listToUpdate);
            }
        },
        customToolbar: () => {
            return <>
                {canListType && isMobileOrTab && <>
                    <IconButton
                        aria-label="sort"
                        aria-controls="sort-menu"
                        aria-haspopup="true"
                        onClick={(e) => setSortAnchorEl(e.currentTarget)}
                        size="large">
                        <SwapVert />
                    </IconButton>
                    <Menu
                        id="sort-menu"
                        className={classes.menuContent}
                        anchorEl={sortAnchorEl}
                        keepMounted
                        open={Boolean(sortAnchorEl)}
                        onClose={() => setSortAnchorEl(null)}
                        elevation={1}
                        getContentAnchorEl={null}
                        disableRestoreFocus
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                    >
                        <MenuList className={classes.menuListStyle} style={{ padding: 0 }}>
                            {columns.map((column, i) => {
                                if (!column || !column.options || column.options.display === "excluded" || !column.options.sort) return null;
                                return (
                                    <StyledMenuItem
                                        key={column.name}
                                        selected={sortData && sortData.name === column.name}
                                        onClick={() => updateSort(column)}
                                    >
                                        {sortData && <ListItemIcon>
                                            <ListItemIcon className={classes.sortListIconStyle}>
                                                {sortData.name === column.name &&
                                                    <ArrowUpward fontSize="small" className={clsx(classes.expandCollapseIcon, sortData.direction === 'asc' && classes.expandLess)} />}
                                            </ListItemIcon>
                                        </ListItemIcon>}
                                        <Typography variant="inherit">{t(column.label)}</Typography>
                                    </StyledMenuItem>
                                )
                            }
                            )}
                            <IconButton
                                aria-label="sort"
                                aria-controls="sort-menu"
                                aria-haspopup="true"
                                onClick={(e) => setSortAnchorEl(e.currentTarget)}
                                size="large">
                                <SwapVert />
                            </IconButton>
                        </MenuList>
                    </Menu>
                </>}
                <Tooltip title={t('workOrders.overview.label')}>
                    <IconButton
                        aria-label="more"
                        aria-controls="long-menu"
                        onClick={overViewHandler}
                        size="large">
                        <DashboardOutlined />
                    </IconButton>
                </Tooltip>
                <DataGridSetting
                    canListType={canListType}
                    imageSize={imageSize}
                    fontSize={fontSize}
                    onChangeImageSize={handleResizeImage}
                    onChangeFontSize={handleResizeFont}
                    onChangeGridViewType={handleChangeGridStyle}
                />
            </>;
        },
        onTableChange: (action, tableState) => {
            let data = getAppliedFilters(tableState.filterList);
            const newRowsPerPage = tableState.rowsPerPage;
            const page = tableState.page;
            const sortDataLocal = Object.keys(tableState.sortOrder).length ? tableState.sortOrder : null;
            if (sortDataLocal) {
                data = Object.assign(data, {
                    orderBy: sortDataLocal.name,
                    orderByAsc: sortDataLocal.direction === "asc",
                });
            };
            switch (action) {
                case "changePage": {
                    UpdateWorkOrders(isLoading, newRowsPerPage, page, tableState.searchText, data);
                    break;
                }
                case "changeRowsPerPage": {
                    UpdateWorkOrders(isLoading, newRowsPerPage, page, tableState.searchText, data);
                    setRowsPerPage(newRowsPerPage);
                    setLocalStorageItem('AfterCare_ResolverWorkOrders_RowsPerPage', newRowsPerPage);
                    break;
                }
                case "search": {
                    search(newRowsPerPage, page, tableState.searchText, data);
                    break;
                }
                case "sort": {
                    if (sortDataLocal) {
                        UpdateWorkOrders(isLoading, newRowsPerPage, page, tableState.searchText, data);
                    };
                    setLocalStorageItem('AfterCare_ResolverWorkOrders_V2_SortOrder', sortDataLocal);
                    setSortData(sortDataLocal);
                    break;
                }
                default: {
                    break;
                }
            }
        },
    };


    const Icons = () => {
        return (<Stack direction='row' mx={isMobileOrTab ? 0 : 2} gap={2}>
            <FilterButton isSelected={appliedQuickFilter?.new} onClick={() => handleApplyQuickFilter("new")}> <Tooltip title={t("resolver.status." + resolverStatus.New)}><span style={{ color: appliedQuickFilter?.new ? "white" :  grey[600] ,fontSize: isMobileOrTab ?15:18 }}>N</span></Tooltip></FilterButton>

            <FilterButton isSelected={appliedQuickFilter?.inprogress} onClick={() => handleApplyQuickFilter("inProgress")}>
                <Tooltip title={t("resolver.status." + resolverStatus.Informed)}>
                    <span>
                        <i className="fa fa-spinner" aria-hidden="true"   style={{ color: appliedQuickFilter?.inprogress ? "white" : grey[600]    }}></i>
                    </span>
                </Tooltip>
            </FilterButton>

            <FilterButton isSelected={appliedQuickFilter?.dueDate} onClick={() => handleApplyQuickFilter("dueDate")}>
                <Tooltip title={t("general.overdue")}>
                    <span>
                        <i className="fa fa-clock-o" aria-hidden="true" style={{ color: appliedQuickFilter?.dueDate ? "white" :  grey[600]  }}></i>
                    </span>
                </Tooltip>
            </FilterButton>
            <FilterButton isSelected={appliedQuickFilter?.completedOrRejected} onClick={() => handleApplyQuickFilter("completedOrRejected")}>
                <Tooltip title={t("workOrders.quickFilter.closedOrCompleted")}>
                    <span>
                        <i className="fa fa-check-circle-o" aria-hidden="true" style={{ color: appliedQuickFilter?.completedOrRejected ? "white" : grey[600] }}></i>
                    </span>
                </Tooltip>
            </FilterButton>
        </Stack>)
    }

    return (
        <Container maxWidth={false} className={classes.mainContainer}>

            <DataGrid
                canListType={canListType}
                CustomRowRender={
                    isMobileOrTab && !!canListType ?
                        (rest) => <AfterCareWorkOrderRow
                            {...rest}
                        />
                        : null
                }
                options={options}
                identifier="resolverId"
                localColumnOrdersIdentifier={generateLocalStorageKey("AfterCare_ResolverWorkOrders_V2_ColumnOrder")}
                columnsVisibilityIdentifier={generateLocalStorageKey("AfterCare_ResolverWorkOrders_V2_ColumnsVisibility")}
                columns={columns}
                data={workOrders}
                rowsPerPageIdentifier={generateLocalStorageKey("AfterCare_ResolverWorkOrders_RowsPerPage")}
                title={isMobileOrTab ? "" : "general.workOrders"}
                loading={isLoading}
                canGoBack={!!filterType}
                getData={getWorkOrdersForPrintAndDownload}
                fontSize={fontSize}
                onRowSelect={(data) => setRowsSelected(data)}
                IconComponent={Icons}
            />
        </Container>
    );
};
