import React, { useState, memo, useEffect, useMemo } from "react";
import { Badge, Button, Tooltip, Typography, IconButton, Box, Avatar, Popover, CardContent, Stack } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { ArrowRightAlt, Chat, PriorityHigh, Schedule } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import clsx from "clsx";
import { formatDate } from "../_helpers";
import CellMenu from "./CellMenu";
import { URLS } from "../apis/urls";
export const IconColumn = ({ value, metaData, idName, data, ...props }) => {
  const id = metaData.rowData[0];
  const selectedRecord = data.find((x) => x[idName] === id);
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <Typography noWrap>
      {value === true && (
        <Tooltip title={<Typography>{t("general.actionRequired")}</Typography>}>
          <PriorityHigh color="secondary" size="small" />
        </Tooltip>
      )}
      {selectedRecord.overdue ? (
        <Tooltip title={<Typography>{t("general.overdue")}</Typography>}>
          <Schedule fontSize="small" color="error" />
        </Tooltip>
      ) : selectedRecord.is48HoursReminder ? (
        <Tooltip title={<Typography>{t("general.48hours")}</Typography>}>
          <Schedule fontSize="small" className={classes.warning} />
        </Tooltip>
      ) : (
        ""
      )}
    </Typography>
  );
};

export const PriorityIconColumnV2 = React.memo(({ value, cellSubMenuOptions, request, ...props }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState(null);
  const [position, setPosition] = useState({ x: 0, y: 0 })

  const handleContextMenu = (event) => {
    event.preventDefault();
    setPosition({
      x: event.clientX,
      y: event.clientY
    })
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const contextProps = useMemo(() => {
    if (cellSubMenuOptions?.options?.length > 0) {
      return {
        onContextMenu: handleContextMenu
      }
    }
    return {}
  }, [cellSubMenuOptions?.options?.length, handleContextMenu])

  return <div {...contextProps} style={{ minWidth: "30px", width: "100%", height: "100%" }}>
    {!request.isAfterCareEmployeeOrSiteManager ? <div className={classes.isBlankCellOfPriority}>-</div> :
      value == 2 ? (
        <Tooltip title={t("repairRequest.priority.2")}>
          <ArrowRightAlt className={classes.rotate45AntiClockwise} color="error" />
        </Tooltip>
      ) : value == 1 ? (
        <Tooltip title={t("repairRequest.priority.1")}>
          <ArrowRightAlt color="primary" />
        </Tooltip>
      ) : value == 0 ? (
        <Tooltip title={t("repairRequest.priority.0")}>
          <ArrowRightAlt className={classes.rotate45Clockwise} />
        </Tooltip>
      ) : (
        ""
      )
    }
    {cellSubMenuOptions?.options?.length > 0 && <CellMenu cellSubMenuOptions={cellSubMenuOptions} row={request} anchorEl={anchorEl} handleMenuClose={handleMenuClose} position={position} />}
  </div>
});


export const Attention = ({ request, t, options }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [position, setPosition] = useState({ x: 0, y: 0 })

  const handleContextMenu = (event) => {
    event.preventDefault();
    setPosition({
      x: event.clientX,
      y: event.clientY
    })
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Typography noWrap component="div" onContextMenu={handleContextMenu} width="100%" display="flex" justifyContent="center" height={"100%"} minWidth={"60px"}>
        <div>
          {request.isRequiredHandling === true && (
            <PriorityHigh color="secondary" size="small" />
          )}
        </div>
        <CellMenu cellSubMenuOptions={{ options: options.filter((op) => op.label !== t("general.copy")), extraData: { fieldName: "isRequiredHandling", type: "boolean" } }} row={request} anchorEl={anchorEl} handleMenuClose={handleMenuClose} position={position} />
      </Typography>
    </>
  )
}

export const HoverImage = ({ imageId, defaultSize, index }) => {
  const [size, setSize] = useState(defaultSize)

  useEffect(() => {
    setSize(defaultSize)
  }, [defaultSize])

  return (
    <>
      {imageId &&
        <Box >
          <Box
            boxShadow={1}
            borderRadius={1}
            className={`img-col-data ${index === 0 ? "img-col-first-data" : ""}`}
            style={{ backgroundImage: `url(${imageId ? URLS.GET_ATTACHMENT_THUMBNAIL + imageId : null})`, backgroundSize: "cover" }}
            sx={{
              width: size, height: size,
              "&:hover": {
                transform: "scale(5)",
                position: "absolute",
                zIndex: "1000000",
                marginTop: index === 0 ? '30px' : 0,
              }
            }}
          >
          </Box>
        </Box>}
    </>
  )
}

export const Overdue = ({ request, t, options, classes }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [position, setPosition] = useState({ x: 0, y: 0 })

  const handleContextMenu = (event) => {
    event.preventDefault();
    setPosition({
      x: event.clientX,
      y: event.clientY
    })
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Typography noWrap component="div" onContextMenu={handleContextMenu} display="flex" justifyContent="center" width="100%" height={"100%"} >
        {!request.isAfterCareEmployeeOrSiteManager ? (
            <Typography className={classes.emptyColumnText}>-</Typography>
        ) : (
          <>
            <div className={classes.iconContainer}>
              {request.overdue ? (
                <Schedule fontSize="small" color="error" />
              ) : request.is48HoursReminder ? (
                <Schedule fontSize="small" className={classes.warning} />
              ) : (
              ""
              )}
            </div>
          </>
        )}
        <CellMenu cellSubMenuOptions={{ options: options.filter((op) => op.label !== t("general.copy")), extraData: { fieldName: "overdue", type: "boolean" } }} row={request} anchorEl={anchorEl} handleMenuClose={handleMenuClose} position={position} />
      </Typography>

    </>
  )
}

export const Messages = ({ request, t, options, classes }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [position, setPosition] = useState({ x: 0, y: 0 })
  const style = useStyles();

  const handleContextMenu = (event) => {
    event.preventDefault();
    setPosition({
      x: event.clientX,
      y: event.clientY
    })
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Typography noWrap component="div" onContextMenu={handleContextMenu} display="flex" justifyContent="center" alignItems="center" width="100%" height="100%" pt={1}>
              {
                  request?.unreadMessagesCount > 0 &&
                  <Badge sx={{ "& .MuiBadge-badge":{height: 14, minWidth: 14 }}}  badgeContent={request.unreadMessagesCount} color="secondary"><Chat fontSize="12px" /></Badge>
              }
        <CellMenu cellSubMenuOptions={{ options: options.filter((op) => op.label !== t("general.copy")), extraData: { fieldName: "unreadMessagesCount", type: "number" } }} row={request} anchorEl={anchorEl} handleMenuClose={handleMenuClose} position={position} />
      </Typography>

    </>
  )
}

export const LinkColumnV2 = memo(({ label, className = {}, cellSubMenuOptions, request, ...props }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [position, setPosition] = useState({ x: 0, y: 0 })

  const handleContextMenu = (event) => {
    event.preventDefault();
    setPosition({
      x: event.clientX,
      y: event.clientY
    })
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const contextProps = useMemo(() => {
    if (cellSubMenuOptions?.options?.length > 0) {
      return {
        onContextMenu: handleContextMenu
      }
    }
    return {}
  }, [cellSubMenuOptions?.options?.length, handleContextMenu])

  return (
    <Box className={classes.locationContainer} {...contextProps}>
      <Button
        style={{ padding: 0 }}
        component={Link}
        className={clsx(className)}
        sx={{ fontSize: '12px' }}
        {...props}
      >
        <span className={clsx(classes.content)}>
          {label}
        </span>
        <div className={classes.spacer}> {label}</div>
        <span>&nbsp;</span>
      </Button>
      {cellSubMenuOptions?.options?.length > 0 && <CellMenu cellData={label} cellSubMenuOptions={cellSubMenuOptions} row={request} anchorEl={anchorEl} handleMenuClose={handleMenuClose} position={position} />}
    </Box>
  );
});

export const MultiLabelColumnV2 = memo(({ data = [], onClick = () => 0, cellSubMenuOptions,
  request, ...rest }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [position, setPosition] = useState({ x: 0, y: 0 })
  const [userListPopover, setUserListPopover] = useState({
    element: null,
    data: null,
  });

  const handleContextMenu = (event) => {
    event.preventDefault();
    setPosition({
      x: event.clientX,
      y: event.clientY
    })
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const contextProps = useMemo(() => {
    if (cellSubMenuOptions?.options?.length > 0) {
      return {
        onContextMenu: handleContextMenu
      }
    }
    return {}
  }, [cellSubMenuOptions?.options?.length, handleContextMenu])

  if (!data || !data.length) return <Box   {...contextProps} {...rest}>  {cellSubMenuOptions?.options?.length > 0 && <CellMenu cellSubMenuOptions={cellSubMenuOptions} row={request} anchorEl={anchorEl} handleMenuClose={handleMenuClose} position={position} />}</Box>;

  const labels = [...new Map(data.map((item) => [item.name, item])).values()];
  const clickHandler = (e, user) => {
    e.preventDefault();
    e.stopPropagation();
    onClick(user)
  }
  return (
    <Stack direction="row"
      whiteSpace="nowrap"
      overflow="hidden"
      textOverflow="ellipsis"
      position="relative"
      {...contextProps}
      {...rest}
    >
      <Box
        whiteSpace="nowrap"
        overflow="hidden"
        textOverflow="ellipsis"
        position="relative"
        display="flex"
        alignItems="center"
      >
        <span className={clsx(classes.shortenText, classes.content)} onClick={(e) => clickHandler(e, labels[0])}>
          {labels[0].name}
        </span>
        <UserLists
          open={!!userListPopover.element}
          data={userListPopover.data}
          onClose={(e) => {
            e.stopPropagation()
            setUserListPopover({ element: null, data: null })
          }}
          onClick={clickHandler}
          anchorEl={userListPopover.element}
        />
        <div className={classes.spacer}> {labels[0].name}</div>
        <span>&nbsp;</span>
      </Box>
      {labels.length - 1 > 0 && (
        <Box ml={1}    >
          <Avatar
            sx={{ cursor: "pointer" }}
            className={classes.smallAvatar}
            onClick={(e) => {
              e.stopPropagation()
              setUserListPopover({ element: e.currentTarget, data: labels })
            }}>
            +{labels.length - 1}
          </Avatar>
        </Box>
      )}
      {cellSubMenuOptions?.options?.length > 0 && <CellMenu cellData={labels[0].name} cellSubMenuOptions={cellSubMenuOptions} row={request} anchorEl={anchorEl} handleMenuClose={handleMenuClose} position={position} />}
    </Stack>
  );
});

export const LabelColumnV2 = memo(({
  spanClass = {},
  color = "",
  label,
  tooltipLabel = "",
  date,
  showIcon,
  Icon,
  onLabelClick,
  clickable,
  onClick,
  isAvatar,
  avatarUrl,
  cellSubMenuOptions,
  request,
  isBlankCell = false,
  ...rest
}) => {

  const [anchorEl, setAnchorEl] = useState(null);
  const [position, setPosition] = useState({ x: 0, y: 0 })

  const handleContextMenu = (event) => {
    event.preventDefault();
    setPosition({
      x: event.clientX,
      y: event.clientY
    })
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const classes = useStyles();

  const contextProps = useMemo(() => {
    if (cellSubMenuOptions?.options?.length > 0) {
      return {
        onContextMenu: handleContextMenu
      }
    }
    return {}
  }, [cellSubMenuOptions?.options?.length, handleContextMenu])

  return (
    <>
      <Box className={classes.locationContainer} {...contextProps}  {...rest} >
        {isBlankCell ? <div className={classes.isBlankCell}>-</div> :
          showIcon ? (
            <>
              <Tooltip
                title={
                  !tooltipLabel ? (
                    ""
                  ) : (
                    <span className={classes.labelTooltip}>{tooltipLabel}</span>
                  )
                }
              >
                <span style={{ color }} className={clsx(classes.label, onLabelClick && classes.cursor)} onClick={() => onLabelClick && onLabelClick(label)}>
                  {clickable ? (
                    <IconButton
                      color="primary"
                      className={classes.mapIconContainer}
                      aria-label="Repair request map redirect"
                      component="span"
                      onClick={onClick}
                      size="large">
                      <Icon className={classes.mapIcon} />
                    </IconButton>
                  ) : (
                    isAvatar ? (
                      <div className={classes.avatarIconContainer}>
                        <Avatar src={avatarUrl} className={classes.avatarIcon} />
                      </div>
                    ) : <Icon className={classes.mapIcon} />
                  )}
                  <Box whiteSpace="nowrap"
                    overflow="hidden"
                    textOverflow="ellipsis"
                    position="relative"
                    alignItems="center" component='span' sx={{ fontSize: '12px !important' }}>
                    {date ? formatDate(new Date(date)) : label}
                  </Box>
                </span>
              </Tooltip>
            </>
          ) : (
            <>
              <Tooltip
                title={
                  !tooltipLabel ? (
                    ""
                  ) : (
                    <span className={classes.labelTooltip}>{tooltipLabel}</span>
                  )
                }
              >
                <Typography
                  noWrap
                  variant="body2"
                  component="span"
                  style={{ color }}
                  sx={{ fontSize: '12px !important' }}
                  className={clsx(classes.iconLabel, classes.label, classes.content, spanClass)}
                >
                  {Icon && <div className={classes.customIconContainer}></div>}
                  <span className={clsx(Icon ? classes.iconTextLabel : classes.textLabel, onLabelClick && classes.cursor)} onClick={(e) => {
                    onLabelClick && onLabelClick(label);
                  }}>
                    {date ? formatDate(new Date(date)) : label}
                  </span>
                </Typography>
              </Tooltip>
            </>
          )
        }
        {!showIcon && !isBlankCell && <div className={classes.spacer}> {date ? formatDate(new Date(date)) : label}</div>}
        <span>&nbsp;</span>
        {cellSubMenuOptions?.options?.length > 0 && <CellMenu cellData={label} cellSubMenuOptions={cellSubMenuOptions} row={request} anchorEl={anchorEl} handleMenuClose={handleMenuClose} position={position} />}
      </Box>
    </>
  );
});

export const ImageColumn = memo(({ src, size = 20 }) => {
  const [url, setUrl] = useState();
  const [base64, setBase64] = useState();
  const classes = useStyles();

  useEffect(() => {
    if (src) {
      setUrl(src);
      if (typeof (src) === "string" && src.startsWith("data:")) {
        setBase64(src);
      }
    };
  }, [src]);

  if (!src) return <div style={{ height: `${size + 4}px` }} />;

  return (
    <div>
      <span className={classes.centerContainer}>
        {<Box
          boxShadow={1}
          borderRadius={1}
          className={classes.thumbnail}
          style={{ backgroundImage: `url(${url})`, width: size, height: size }}
        >
          {base64 && <img src={base64} alt={"offline-img"} className={classes.base64Image} />}
        </Box>}
        {src && (
          <aside
            className={clsx(classes.popoverImage, classes.fixedContainer)}
          >
            <Box
              boxShadow={1}
              borderRadius={1}
              className={classes.thumbnailAbsolute}
              style={{ backgroundImage: `url(${src})` }}
            >
              {base64 && <img src={base64} alt={"offline-img"} className={classes.base64Image} />}
            </Box>
          </aside>
        )}
      </span>
    </div>
  );
});

export const UserLists = memo(({
  open,
  anchorEl: anchorElModal,
  onClick,
  data,
  ...props
}) => {
  const classes = useStyles();

  return data ? (
    <Popover
      id="mouse-over-popover"
      style={{ overflow: "hidden" }}
      anchorEl={anchorElModal}
      open={!!anchorElModal && data && data.length}
      disableRestoreFocus
      classes={{ root: classes.root, paper: classes.paper }}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      {...props}
    >
      <div>
        {data && (
          <CardContent className={classes.cardContentStyle}>
            {data.map((user) => {
              return (
                <span className={classes.headerContent} onClick={(e) => onClick(e, user)}>{user.name}</span>
              );
            })}
          </CardContent>
        )}
      </div>
    </Popover>
  ) : null;
});

const useStyles = makeStyles((theme) => ({
  root: {},
  paper: {
    boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
    borderRadius: 7,
    minWidth: 150,
  },
  cardContentStyle: {
    padding: theme.spacing(0, 2),
    paddingBottom: `${theme.spacing(0)} !important`,
    display: "flex",
    flexDirection: "column",
  },
  cardList: {
    padding: theme.spacing(0.4, 0, 0.4, 0),
  },
  cardAvatar: {
    marginRight: `8px !important`,
  },
  warning: {
    color: theme.palette.warning.main,
  },
  mapIcon: {
    fill: theme.palette.primary.main,
    fontSize: 18
  },
  avatarIconContainer: {
    display: 'inline-block',
    verticalAlign: 'middle',
  },
  avatarIcon: {
    marginRight: 5,
    width: 18,
    '& > svg': {
      height: 14,
      width: 14
    }
  },
  customIconContainer: {
    minWidth: 27,
  },
  iconTextLabel: {
    width: "calc(100% - 27px)",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  textLabel: {
    width: "100%",
    maxWidth: "100%",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  locationContainer: {
    display: "flex",
    alignItems: "center",
    position: "relative",
    overflow: "hidden",
    cursor: "pointer",
    width: "100%"
  },
  mapIconContainer: {
    padding: theme.spacing(0.2),
  },
  tooltipImageContainer: {
    "& > img": {
      width: "100%",
    },
  },
  thumbnailAbsolute: {
    width: 20,
    height: 20,
    transition: "0.2s all",
    margin: "auto",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "contain",
    backgroundColor: theme.palette.grey[300],
    marginTop: 2,
    position: "absolute",
    opacity: 0,
    marginBottom: 2,
  },
  thumbnail: {
    transition: "0.2s all",
    margin: "auto",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "contain",
    backgroundColor: theme.palette.grey[300],
    marginTop: 2,
    marginBottom: 2,
    overflow: "hidden"
  },
  rotate45Clockwise: {
    transform: "rotate(45deg)",
  },
  rotate45AntiClockwise: {
    transform: "rotate(-45deg)",
  },
  isBlankCell: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center"
  },
  isBlankCellOfPriority: {
    maxWidth: "18px", 
    width: "100%",
     height: "100%", 
     display: "flex", 
     justifyContent: "center"
  },
  smallAvatar: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    width: theme.spacing(2.5),
    height: theme.spacing(2.5),
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: 9,
    position: "relative",
    border: "2px solid white",
    right: 0,
    zIndex: 4,
  },
  shortenText: {
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
      paddingLeft: 5
    },
    cursor: "pointer"
  },
  trimResolverLabel: {
    maxWidth: "calc(100% - 25px) !important"
  },
  userListAvatar: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    width: theme.spacing(3),
    height: theme.spacing(3),
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: 12,
  },
  headerContent: {
    fontSize: `14px !important`,
    margin: 4,
    cursor: "pointer"
  },
  label: {
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    fontSize: "inherit"
  },
  wrapContainer: {
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
  },
  labelTooltip: {
    fontSize: 14,
  },
  popoverImage: {
    "&:hover": {
      "& > div": {
        width: "160px !important",
        height: "160px !important",
      },
    },
  },
  centerContainer: {
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  fixedContainer: {
    position: "absolute",
    zIndex: 9999,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  base64Image: {
    height: "100%",
    width: "100%",
  },
  content: {
    position: "absolute",
    maxWidth: "100%",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  spacer: {
    height: 0,
    overflow: "hidden",
  },
  iconLabel: {
    display: "flex",
    alignItems: "center"
  },
  cursor: {
    cursor: "pointer"
  }
}));