
import moment from 'moment';
import 'moment/locale/fr';
import 'moment/locale/nl';
import 'moment/locale/de';

export function formatDate(date, withTime, separator = '-', is24Hours = true) {
    var dd = String(date.getDate()).padStart(2, '0');
    var mm = String(date.getMonth() + 1).padStart(2, '0');
    var yyyy = date.getFullYear();
    var time = '';
    if (withTime)
        time = " " + (is24Hours ? String(date.getHours()).padStart(2, '0') + ":" + String(date.getMinutes()).padStart(2, '0') :
            date.toLocaleString([], { hour: '2-digit', minute: '2-digit' }));
    return dd + separator + mm + separator + yyyy + time;
}

export function formatTime(obj, is24Hours = false) {
    var isDate = obj instanceof Date;
    var hours = 0, minutes = 0;

    if ((typeof obj) === "string")
        return obj.split(":").splice(0, 2).join(":")

    if (isDate === true) {
        hours = obj.getHours();
        minutes = obj.getMinutes();
    }
    else if (!!obj) {
        hours = obj.hours;
        minutes = obj.minutes;
    }
    var hh = String(hours).padStart(2, '0');
    var mm = String(minutes).padStart(2, '0');
    if (is24Hours) {
        return String(obj.getHours()).padStart(2, '0') + ":" + String(obj.getMinutes()).padStart(2, '0');

    }
    return hh + ':' + mm;
}

export function formatStartEndDateTime(event) {
    const start = moment(event.start);
    const end = moment(event.end);

    if (start.diff(end, 'days') === 0)
        return `${start.format('dddd DD-MM-YYYY')}, ${start.format('HH:mm')} - ${end.format('HH:mm')}`
    return `${start.format('dddd DD-MM-YYYY')}, ${start.format('HH:mm')} - ${end.format('dddd DD-MM-YYYY')}, ${end.format('HH:mm')}`

}
export function getWeekName(date) {
    return date.toLocaleDateString('nl-NL', { weekday: 'long' });
}

export function getDateText(date) {
    //const formatDate = new Intl.DateTimeFormat('nl-NL', { day: '2-digit', month: '2-digit', year: 'numeric' });
    //const formatTime = new Intl.DateTimeFormat('nl-NL', { hour: '2-digit', minute: '2-digit' });
    //const formatDay = new Intl.DateTimeFormat('nl-NL', { weekday: 'long' });
    var now = new Date().getTime();
    var dateTime = date.getTime();
    var daysDiff = Math.round((now - dateTime) / (1000 * 3600 * 24));
    switch (daysDiff) {
        case 0: { return formatTime(date); }
        case 1:
        case 2:
        case 3:
        case 4:
        case 5:
        case 6: { return getWeekName(date); }
        default: {
            return formatDate(date);
        }
    }
}

export function isValidDate(d) {
    return d && d instanceof Date && !isNaN(d);
}

export function generateUniqueString() {
    const timestamp = new Date().getTime().toString();
    const randomString = Math.random().toString(36).substring(2, 8);
    return timestamp + randomString;
}